











































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { showError, showSuccess, showWarning } from "@/utils/Common";
import {
  MSG_ADD_SUCCESS,
  MSG_INVALID_PAGE,
  MSG_MODIFY_SUCCESS
} from "@/configs/Consts";

@Component
export default class UserForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  userItem!: any;

  modifyPhone = false;
  modifyEmail = false;

  isEditUser() {
    return this.userItem && this.userItem.id;
  }

  queryEnterprise(query: string, cb: any) {
    const allData = this.$store.getters["enums/getEnumsByKey"]("Customer");
    const datas: Array<any> = [];
    allData.forEach((data: any) => {
      datas.push({ value: data.label });
    });
    const results = query ? datas.filter((data: any) => {
      return data.value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  saveUser() {
    const form = this.$refs.userForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const userData = JSON.parse(JSON.stringify(this.userItem));
      userData.userType = userData.userType || "CUSTOMER";
      this.$store
        .dispatch(
          this.isEditUser() ? "users/updateUser" : "users/addUser",
          userData
        )
        .then(() => {
          this.$emit("update:userItem", {});
          showSuccess(this.isEditUser() ? MSG_MODIFY_SUCCESS : MSG_ADD_SUCCESS);
          this.$emit("data-success");

          // reload enums
          this.$store.dispatch("enums/getEnums");
        })
        .catch(({ message }) => showError(message));
    });
  }
}





































































































































































































































import { API_EXPORT_TASK_SYNC, API_USER_LIST } from "@/configs/Apis";
import { MSG_EXPORT_FILE_NOTFOUND } from "@/configs/Consts";
import formatters from "@/utils/ColumnFormater";
import {
  hasPermission,
  isEmpty,
  isEmptyOrWhiteSpace,
  mergeColumns,
  showConfirm,
  showError,
  showSuccess,
  showWarning,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import DataImporter from "@/views/importer/DataImporter.vue";
import UserForm from "@/views/user/subviews/UserForm.vue";
import { TableColumn } from "element-ui/types/table-column";
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    UserForm,
    DataImporter,
  },
})
export default class UserIndex extends Vue {
  nodeQueryData = "";
  queryData: any = { keyword: "", userType: "CUSTOMER" };

  addUserFormDialogVisible = false;
  userItem: any = { id: 0 };

  userImportVisible = false;

  batchUserFilter: any = {};

  hasSelectedItems = false;

  queryStr = "";
  loadData = false;

  showSearch = false;

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  get treeData() {
    const module = this.$store.state.users;
    if (!module) {
      return null;
    }
    return module.userTree;
  }

  onTreeNodeClick(index: string) {
    if (isEmptyOrWhiteSpace(index)) {
      return;
    }
    const parts = index.split("-");
    let nodeData: any = null;
    if (parts.length === 1) {
      nodeData = this.treeData[parts[0]];
    } else {
      nodeData = this.treeData[parts[0]].children[parts[1]];
    }
    if (!nodeData) {
      return;
    }
    this.nodeQueryData = JSON.stringify(nodeData.userFilter || {});
    // this.onQueryChanged();
  }

  get searchBtnIcon(): string {
    return this.showSearch ? "el-icon-arrow-up" : "el-icon-arrow-down";
  }

  get dataApi() {
    return API_USER_LIST;
  }

  get colFormatters() {
    return formatters;
  }

  createTableColumns() {
    const defaultColumns: Array<TableColumnOption> = [
      { prop: "loginName", display: "账号", width: 200 },
      { prop: "name", display: "姓名", width: 200 },
      { prop: "enterpriseName", display: "所属单位", width: 260 },
      { prop: "email", display: "邮箱", width: 240 },
      { prop: "phone", display: "电话", width: 200 },
      { prop: "disabled", display: "状态", width: 110 },
      { prop: "weixinUid", display: "微信UID" },
    ];
    this.$store
      .dispatch("users/getListLayout", { listName: "USER" })
      .then((layout) => {
        const columns = mergeColumns(
          JSON.parse(layout || "[]"),
          defaultColumns
        );
        this.applyColumnFormatters(columns);
        return columns;
      })
      .then((columns) => this.$store.dispatch("users/setColumns", columns));
  }

  applyColumnFormatters(columns: Array<TableColumnOption>) {
    const fmers = {
      disabled: this.colFormatters.formatUserStatus.bind(this),
      phone: this.colFormatters.formatPhone.bind(this),
      email: this.colFormatters.formatEmail.bind(this),
    };
    if (!columns || !columns.length) {
      return;
    }
    columns.forEach((option) => {
      if (!Object.prototype.hasOwnProperty.call(fmers, option.prop)) {
        return;
      }
      option.formatter = (fmers as any)[option.prop];
    });
  }

  importUser() {
    this.userImportVisible = true;
  }

  closeImportDialog() {
    this.userImportVisible = false;
    this.reloadTreeAndData();
  }

  reloadTreeAndData() {
    this.loadUserTree();
    this.searchData();
  }

  onModuleRegistered() {
    this.$nextTick()
      .then(() => this.createTableColumns())
      .then(() => {
        this.loadUserTree();
        this.onSearchConditionChange();
      });
  }

  loadUserTree() {
    return this.$store.dispatch("users/getUserTree");
  }

  onTextKeyup() {
    ((this.$refs.searchBtn as Vue).$el as HTMLInputElement).focus();
  }

  onSearchConditionChange() {
    const params: { [key: string]: any } = {};
    if (!isEmptyOrWhiteSpace(this.nodeQueryData)) {
      const nodeFilter = JSON.parse(this.nodeQueryData);
      Object.keys(nodeFilter).forEach(
        (name) => (params[name] = nodeFilter[name])
      );
    }
    params.userType = "CUSTOMER";
    if (!isEmptyOrWhiteSpace(this.queryData.keyword)) {
      params.keyword = this.queryData.keyword;
    }
    const paramsStr = JSON.stringify(params);
    if (paramsStr === "{}" && this.queryStr.trim() === "") {
      this.queryStr = "{}";
      return;
    }
    if (paramsStr !== this.queryStr.trim()) {
      this.queryStr = JSON.stringify(params);
    }
  }

  @Watch("nodeQueryData")
  onNodeQueryDataChange() {
    this.queryData = { keyword: "", userType: "CUSTOMER" };
    this.onSearchConditionChange();
  }

  // @Watch("queryData.keyword")
  // onSeachPanelConditionChange() {
  //   this.onSearchConditionChange();
  // }

  searchData() {
    if (this.queryStr.endsWith(" ")) {
      this.queryStr = this.queryStr.trim();
    } else {
      this.queryStr += " ";
    }
  }

  addUser() {
    this.userItem = { id: 0 };
    this.addUserFormDialogVisible = true;
  }

  addUserSuccess() {
    this.addUserFormDialogVisible = false;
    this.reloadTreeAndData();
    this.userItem = { id: 0 };
  }

  editUser() {
    const selectedRows = (this.$refs.usergrid as any).getSelectedDataRows();
    if (!selectedRows || selectedRows.length === 0) {
      showWarning("请选择一个客户");
      return;
    }
    this.userItem = JSON.parse(JSON.stringify(selectedRows[0]));
    this.userItem.phone = "";
    this.userItem.email = "";
    this.addUserFormDialogVisible = true;
  }

  exportData(cmd: string) {
    let dataFilter: any = {};
    if (cmd === "selected") {
      const selectedRows = (this.$refs.usergrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      dataFilter.selectedIds = selectedRows;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        dataFilter = JSON.parse(this.queryStr);
      }
    }
    const fieldParams: Array<any> = (
      (this.$refs.usergrid as any).columns || []
    ).map((column: any) => {
      return {
        field: column.prop,
        label: column.display,
        width: column.width || 30,
      };
    });
    const params: any = {
      dataType: "User",
      fieldParams: JSON.stringify(fieldParams),
      dataFilter: JSON.stringify(dataFilter),
    };
    Ajax.queryData(API_EXPORT_TASK_SYNC, params)
      .then((task: any) => {
        const url = task.resultUrl || "";
        if (isEmpty(url)) {
          showWarning(MSG_EXPORT_FILE_NOTFOUND);
          return;
        }
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        document.body.appendChild(a);
        a.click();
        if (a.parentNode) {
          a.parentNode.removeChild(a);
        }
      })
      .catch(({ message }) => showError(message));
  }

  onSelectionChanged(selectedRows: any[]) {
    this.hasSelectedItems = selectedRows.length > 0;
  }

  onRowClick(command: string, data: any, event: MouseEvent) {}

  onColumnConfiged({
    data = [],
    hidden = [],
  }: {
    data: Array<TableColumnOption>;
    hidden: Array<TableColumnOption>;
  }) {
    const newData: Array<TableColumnOption> = JSON.parse(JSON.stringify(data));
    const newHidden: Array<TableColumnOption> = JSON.parse(
      JSON.stringify(hidden)
    );
    // 删除属性
    newData.forEach((item) => delete item.hidden);
    if (hidden && hidden.length) {
      newData.push(
        ...newHidden.map((val) => Object.assign(val, { hidden: true }))
      );
    }
    this.$store
      .dispatch("users/saveListLayout", {
        listName: "USER",
        layout: JSON.stringify(newData),
      })
      .then(() => this.$store.dispatch("users/setColumns", []))
      .then(() => this.createTableColumns())
      .catch(({ message }) => showError(message));
  }

  onColumnResize(newWidth: number, column: TableColumn) {
    this.$store.dispatch("users/setColumnWidth", {
      listName: "user",
      column: column.property,
      newWidth,
    });
  }

  openColumnConfig() {
    const grid = this.$refs.usergrid as any;
    grid.openColumnConfig();
  }

  onTableDataLoaded() {
    //
  }

  openSearchPanel() {
    this.showSearch = !this.showSearch;
  }

  batchDeleteData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.usergrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`是否确认删除 ${msg}？`).then(() => {
      this.$store
        .dispatch("users/deleteUser", params)
        .then(() => {
          showSuccess("删除成功");
          this.reloadTreeAndData();
        })
        .catch(({ message }) => showError(message));
    });
  }

  batchDisableData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.usergrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`是否确认停用 ${msg}？`).then(() => {
      this.$store
        .dispatch("users/disableUser", params)
        .then(() => {
          showSuccess("停用成功");
          this.reloadTreeAndData();
        })
        .catch(({ message }) => showError(message));
    });
  }

  batchEnableData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.usergrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`是否确认启用 ${msg}？`).then(() => {
      this.$store
        .dispatch("users/enableUser", params)
        .then(() => {
          showSuccess("启用成功");
          this.reloadTreeAndData();
        })
        .catch(({ message }) => showError(message));
    });
  }
}
















































































































































































































































import {
  API_IMPORT_MAPPING,
  API_IMPORT_PREVIEW,
  API_IMPORT_TASK,
  API_UPLOAD_PATENT_EXCEL,
} from "@/configs/Apis";
import { SERVER_HOST } from "@/configs/AppParams";
import {
  isEmptyOrWhiteSpace,
  showConfirm,
  showError,
  showWarning,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
declare interface ImportFieldMapping {
  entityField: string;
  entityFieldLabel: string;
  excelHeader: string;
  primaryKey: boolean;
}
declare interface FieldMapEntity {
  fieldMappings: Array<ImportFieldMapping>;
  unMatchedHeaders: Array<string>;
}
@Component
export default class DataImporter extends Vue {
  @Prop({ type: Boolean, default: false })
  visible!: boolean;

  @Prop({ type: String, default: "" })
  dataType!: string;

  activeStep = 0;
  excelFile: Nullable<{ name: string; url: string }> = null;
  processing = false;
  importTaskId = 0;
  preview = {
    columns: [""],
    data: [],
  };

  mapping = {
    allExcelHeaders: [],
    data: [],
  };

  duplicateStrategy = "SKIP";
  autoCreateEnum = true;
  stepStatus = {
    uploaded: false,
    previewLoaded: false,
    mappingLoaded: false,
  };

  importData: any = { status: "NOT_STARTED" };

  patentUserFields: any = [
    { name: "userPhone", label: "关联客户: 手机号" },
    { name: "userEnterprise", label: "关联客户: 所属单位" },
    { name: "userName", label: "关联客户: 姓名" },
    { name: "userLoginName", label: "关联客户: 账号" },
    { name: "userEmail", label: "关联客户: 邮箱" },
  ];

  get taskImportStatus() {
    let message = "";
    switch ((this.importData as any).status) {
      case "EXECUTING":
        message = "导入运行中";
        break;
      case "COMPLETED":
        message = "导入完成";
        break;
      case "ABEND":
        message = "导入终止";
        break;
      case "NOT_STARTED":
      default:
        message = "未开始";
        break;
    }
    return message;
  }

  get importPercentage() {
    const message = (this as any).importData;
    if (message.status === "COMPLETED" || message.status === "ABEND") {
      return 100;
    }
    if (message.status === "NOT_STARTED") {
      return 0;
    }
    return parseInt(
      (
        ((message.succeed + message.failed + message.skipped) /
          (message.total || 1)) *
        100
      ).toString()
    );
  }

  get uploadApi() {
    return `${SERVER_HOST}${API_UPLOAD_PATENT_EXCEL}`;
  }

  @Watch("activeStep")
  onActiveStep(step: number, oldStep: number) {
    if (step > oldStep) {
      const handler = (this as any)[`onStep${step}Load`];
      if (
        handler &&
        Object.prototype.toString.call(handler) === "[object Function]"
      ) {
        handler.call(this);
      }
    }
    this.$emit("step-change", step);
  }

  @Watch("processing")
  onProcessing() {
    this.$emit("processing", this.processing);
  }

  validateFileType(file: File) {
    const ext = /\.\w+$/g.exec(file.name);
    if (!ext || [".xls", ".xlsx"].indexOf(ext[0].toLowerCase()) === -1) {
      showWarning("导入文件格式只支持Excel格式");
      return false;
    }
    return true;
  }

  onUploadChange(file: File) {
    if (this.excelFile && this.excelFile.name === file.name) {
      return;
    }
    this.excelFile = { name: file.name, url: "" } as any;
  }

  onRemoveFile() {
    this.excelFile = null;
    (this.$refs.uploader as any).uploadFiles = [];
    this.stepStatus.uploaded = false;
  }

  isEmpty(value: string) {
    return isEmptyOrWhiteSpace(value);
  }

  closeImportForm() {
    this.$emit("update:visible", false);
    this.$emit("close", ((this.importData || {}) as any).status);
  }

  doStep0() {
    const uploader = this.$refs.uploader as any;
    if (this.stepStatus.uploaded && !uploader.uploadFiles.length) {
      this.activeStep += 1;
      return;
    }
    if (!uploader.uploadFiles || !uploader.uploadFiles.length) {
      showWarning("请选择上传的文件");
      return;
    }
    this.processing = true;
    uploader.submit();
    this.stepStatus.mappingLoaded = false;
    this.stepStatus.previewLoaded = false;
  }

  doStep1() {
    this.activeStep += 1;
  }

  onStep1Load() {
    if (this.stepStatus.previewLoaded) {
      return;
    }
    this.processing = true;

    Ajax.saveData(
      API_IMPORT_PREVIEW,
      { fileUrl: this.excelFile?.url, dataType: this.dataType },
      "POST"
    )
      .then(({ header, data } = { header: [], data: [] }) => {
        this.preview.columns = header as any;
        this.preview.data = data.map((item: any) =>
          item.reduce((prev: any, current: any, index: any) => {
            prev[`col${index}`] = current;
            return prev;
          }, {})
        ) as any;
        this.stepStatus.previewLoaded = true;
      })
      .catch(() => "")
      .then(() => {
        this.processing = false;
      });
  }

  onStep2Load() {
    if (this.stepStatus.mappingLoaded) {
      return;
    }
    this.processing = true;
    Ajax.saveData(
      API_IMPORT_MAPPING,
      { fileUrl: this.excelFile?.url, dataType: this.dataType },
      "POST"
    )
      .then((entity = { fieldMappings: [], unMatchedHeaders: [] }) => {
        this.processing = false;
        this.mapping.data = entity.fieldMappings as any;
        const excelHeaders: Array<string> = [];
        entity.fieldMappings.forEach((field: any) => {
          if (!isEmptyOrWhiteSpace(field.excelHeader)) {
            if (excelHeaders.indexOf(field.excelHeader) < 0) {
              excelHeaders.push(field.excelHeader);
            }
          }
        });
        Array.prototype.push.apply(excelHeaders, entity.unMatchedHeaders);
        this.mapping.allExcelHeaders = excelHeaders as any;
        this.stepStatus.mappingLoaded = true;
      })
      .catch(() => {
        this.processing = false;
      });
  }

  doStep2() {
    // 检查匹配字段
    const data: Array<ImportFieldMapping> = this.mapping.data;
    if (!this.mapping.data || !this.mapping.data.length) {
      showWarning("没有找到导入字段映射");
      return;
    }
    let hasUnMatched = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].primaryKey && isEmptyOrWhiteSpace(data[i].excelHeader)) {
        showWarning("主键字段没有匹配，不能导入");
        return;
      }
      if (isEmptyOrWhiteSpace(data[i].excelHeader)) {
        hasUnMatched = true;
        break;
      }
    }
    showConfirm(
      `${hasUnMatched ? "存在没有匹配的字段，" : ""}是否执行导入？`
    ).then(() => {
      if (process.env.VUE_APP_TRIAL === "1") {
        showWarning("产品演示系统，未开放本功能");
        return;
      }
      const postedParams = {
        dataType: this.dataType,
        fileUrl: this.excelFile?.url,
        duplicateStrategy: this.duplicateStrategy,
        autoCreateEnum: this.autoCreateEnum,
        fieldMappings: JSON.stringify(this.mapping.data),
      };
      Ajax.saveData(API_IMPORT_TASK, postedParams, "POST").then(
        (taskId: number) => {
          this.importTaskId = taskId;
          this.activeStep += 1;
        }
      );
    });
  }

  onStep3Load() {
    if (!this.importTaskId) {
      return;
    }
    this.processing = true;
    this.refreshImportProgress();
    this.processing = false;
  }

  taskToken = 0;
  refreshImportProgress() {
    const url = `${API_IMPORT_TASK}/${this.importTaskId}`;
    Ajax.queryData(url, {}, "GET", false)
      .then((task) => {
        if (task.status === "COMPLETED" || task.status === "ABEND") {
          clearTimeout(this.taskToken);
        } else {
          this.taskToken = setTimeout(() => {
            this.refreshImportProgress();
          }, 2000);
        }
        this.importData = task;
      })
      .catch(() => "");
  }

  onUploadSuccess(res: { location: string }) {
    this.processing = false;
    this.stepStatus.uploaded = true;
    if (this.excelFile) {
      this.excelFile.url = res.location.replace(/"/g, "");
    }
    this.activeStep += 1;
  }

  onUploadError(...args: any[]) {
    showError(args[0]);
    this.processing = false;
  }

  doNextStep() {
    if (this.processing) {
      return;
    }
    const handler = (this as any)[`doStep${this.activeStep}`];
    if (
      handler &&
      Object.prototype.toString.call(handler) === "[object Function]"
    ) {
      handler.call(this);
    }
  }

  doPrevStep() {
    if (this.activeStep === 0) {
      return;
    }
    this.activeStep -= 1;
  }
}
